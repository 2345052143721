<template>
  <a :href="`mailto:${email}`" class="custom-btn email-btn">
    <img src="@/assets/images/icons/envelope.svg" alt="email-icon" />
  </a>
</template>

<script>
export default {
  props: {
    email: String,
  },
}
</script>

<style lang="scss" scoped></style>
