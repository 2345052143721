<template>
  <div v-if="customer" class="customer-detail">
    <title-bar
      edit
      exportBtn
      :title="`${customer.givenName} ${customer.familyName}`"
      @editPressed="goToEdit(customer.id)"
    />
    <div v-if="customer.address" class="customer-detail__info">
      <div class="address">
        <span>{{ `${customer.address.streetAddress} ${customer.address.streetNumber}` }}</span>
      </div>
      <div class="floor">
        <span v-if="customer.address.gate"
          >Entrance {{ customer.address.gate }}</span
        >
        <span v-if="customer.address.flatNumber"
          >Flat {{ customer.address.flatNumber }}</span
        >
        <span v-if="customer.address.floorNumber"
          >{{ customer.address.floorNumber }}. floor</span
        >
      </div>
      <div class="contact">
        <div v-if="customer.telephone" class="contact__number">
          <span class="number">{{ customer.telephone }}</span>
          <call-btn :number="customer.telephone" />
        </div>
        <div v-if="customer.email" class="contact__email">
          <span class="email">{{ customer.email }}</span>
          <email-btn :email="customer.email" />
        </div>
      </div>
    </div>

    <div v-if="orders" class="d-flex justify-content-end mb-4">
      <flat-pickr
        v-model="date"
        :config="{
          mode: 'range',
          altFormat: 'd.m.Y',
          dateFormat: 'Y-m-d',
          altInput: true,
          altInputClass: 'flatpickr-input',
          locale: {
            rangeSeparator: ' - ',
          },
        }"
      ></flat-pickr>
    </div>

    <vue-good-table
      v-if="orders"
      :isLoading.sync="isLoading"
      class="custom-table-style"
      mode="remote"
      :columns="columns"
      :rows="orders"
    >
      <template slot="table-row" slot-scope="props">
        <span :class="props.column.field" v-if="props.column.field == 'status'">
          <table-bullet :value="props.formattedRow[props.column.field]" />
        </span>
        <span
          :class="props.column.field"
          v-else-if="props.column.field == 'shippingAddress'"
        >
          <div class="order-info">
            <div class="order-info-flex">
              <div class="time-container">
                <span class="number">#{{ props.row.id }}</span>
                <div class="time">
                  <span>{{ props.row.createdAt | moment('HH:ss') }}</span>
                  <img
                    src="@/assets/images/icons/arrow-right-small.svg"
                    alt="arrow-right"
                  />
                  <span v-if="props.row.status === 'NEW'">Unknown</span>
                  <span v-else-if="props.row.status === 'CLOSED'">{{
                    props.row.closedAt | moment('HH:ss')
                  }}</span>
                  <span v-else>{{
                    $helper.getEnumTranslation('order_status', props.row.status)
                  }}</span>
                </div>
              </div>
              <div class="name">
                {{ `${props.formattedRow[props.column.field].streetAddress} ${props.formattedRow[props.column.field].streetNumber ? props.formattedRow[props.column.field].streetNumber : ''}` }}
              </div>
              <div
                class="place"
                v-if="
                  props.formattedRow[props.column.field].flatNumber ||
                  props.formattedRow[props.column.field].floorNumber ||
                  props.formattedRow[props.column.field].gate
                "
              >
                <!--<span class="entrance">Entrance 8</span>-->
                <span
                  v-if="props.formattedRow[props.column.field].gate"
                  class="entrance"
                  >Entrance
                  {{ props.formattedRow[props.column.field].gate }}</span
                >
                <span
                  v-if="props.formattedRow[props.column.field].flatNumber"
                  class="flat"
                  >Flat
                  {{ props.formattedRow[props.column.field].flatNumber }}</span
                >
                <span
                  v-if="props.formattedRow[props.column.field].floorNumber"
                  class="floor"
                  >{{ props.formattedRow[props.column.field].floorNumber }}.
                  floor</span
                >
              </div>
            </div>
          </div>
        </span>
        <span
          :class="props.column.field"
          v-else-if="props.column.field == 'totalPrice'"
        >
          {{ props.formattedRow[props.column.field] | price }}
        </span>
        <span
          :class="props.column.field"
          v-else-if="props.column.field == 'paymentMethod'"
        >
          {{
            $helper.getEnumTranslation(
              'order_payment_method',
              props.row.paymentMethod,
            )
          }}
        </span>
        <span
          :class="props.column.field"
          v-else-if="props.column.field == 'user'"
        >
          <div class="contact">
            <div class="contact-info">
              <span class="name">
                {{ props.formattedRow[props.column.field].givenName }}
                {{ props.formattedRow[props.column.field].familyName }}
              </span>
              <span
                v-if="props.formattedRow[props.column.field].telephone"
                class="number"
                >{{ props.formattedRow[props.column.field].telephone }}</span
              >
              <span
                v-if="props.formattedRow[props.column.field].email"
                class="email"
                >{{ props.formattedRow[props.column.field].email }}</span
              >
            </div>
            <call-btn
              v-if="props.formattedRow[props.column.field].telephone"
              :number="props.formattedRow[props.column.field].telephone"
            />
          </div>
        </span>
        <span v-else-if="props.column.field == 'courier'">
          <div class="dropdown-container">
            <div class="main-dropdown">
              <b-form-group>
                <v-select
                  v-model="props.row.courierObj"
                  :options="couriers"
                  class="custom-select"
                  :clearable="false"
                  :searchable="true"
                  @input="updateCourier(props.row)"
                >
                  <template slot="selected-option" slot-scope="option">
                    <div>
                      <img
                        v-if="props.row.courier && props.row.courier.fileUrl"
                        class="select-photo"
                        :src="`${props.row.courier.fileUrl}`"
                        alt=""
                      />
                      <span>{{ option.label }}</span>
                    </div>
                  </template>
                  <template #option="{ label }">
                    <img
                      v-if="props.row.courier && props.row.courier.fileUrl"
                      class="select-photo"
                      :src="`${props.row.courier.fileUrl}`"
                      alt=""
                    />
                    <span> {{ label }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </div>
          </div>
        </span>
        <span class="buttons" v-else-if="props.column.field == 'id'">
          <div class="just-buttons">
            <edit-btn path="/" />
            <delete-btn pressDelete="#" />
            <b-button
              v-b-toggle="`collapse-${props.formattedRow[props.column.field]}`"
              @click="windowResize"
              class="custom-btn custom-collapse"
            >
              <img src="@/assets/images/icons/arrow-up.svg" />
            </b-button>
          </div>
          <div class="collapsible-content-row">
            <b-collapse
              :id="`collapse-${props.formattedRow[props.column.field]}`"
            >
              <div
                v-for="(item, index) of props.row.items"
                :key="index"
                class="ordered-item"
              >
                <div class="item">
                  <div class="amount">{{ item.quantity }} pcs</div>
                  <div class="item-name">{{ item.name }}</div>
                </div>
                <div class="item-price">{{ item.price | price }}</div>
              </div>

              <div class="total-order-price">
                <span>Total</span>
                <span>{{ props.row.totalPrice | price }}</span>
              </div>

              <div v-if="props.row.description" class="order-comment">
                <span>Comment</span>
                <p class="comment-text">{{ props.row.description }}</p>
              </div>
            </b-collapse>
          </div>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import TitleBar from '@/components/TitleBar.vue'
import TableBullet from '../../../components/Table/TableBullet.vue'
import EmailBtn from '@/components/Buttons/EmailBtn.vue'
import router from '@/router'
import CallBtn from '@/components/Buttons/CallBtn.vue'
import EditBtn from '@/components/Buttons/EditBtn.vue'
import DeleteBtn from '@/components/Buttons/DeleteBtn.vue'
import ListUtils from '@/mixins/ListUtils'
import Toast from '@/components/Toast.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'CustomerDetail',
  components: {
    TitleBar,
    CallBtn,
    EmailBtn,
    flatPickr,
    VueGoodTable,
    TableBullet,
    EditBtn,
    DeleteBtn,
  },
  mixins: [ListUtils],
  data() {
    return {
      date: null,
      customer: null,
      orders: null,
      couriers: [],

      isLoading: false,
      columns: [
        {
          field: 'status',
          width: '70px',
          sortable: false,
        },
        {
          field: 'shippingAddress',
          sortable: false,
        },
        {
          field: 'totalPrice',
          type: 'number',
          sortable: false,
        },
        {
          field: 'paymentMethod',
          sortable: false,
        },
        {
          field: 'user',
          sortable: false,
        },
        {
          field: 'customer',
          sortable: false,
        },
        {
          field: 'courier',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
          tdClass: 'collapsible',
          width: '50px',
        },
      ],
    }
  },
  created() {
    window.addEventListener('resize', this.windowResize)
    this.getUserList(
      { params: { role: ['ROLE_COURIER'], 'order[familyName]': 'asc' } },
      this.couriers,
    )
    this.loadCustomer()
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
  },
  watch: {
    date(newVal) {
      if (newVal && newVal.split(' - ').length === 2) {
        this.loadOrders()
      }
    },
  },
  methods: {
    loadCustomer() {
      const { id } = router.currentRoute.params
      this.$Users.getResource({ id }).then(response => {
        this.customer = response.data
        if (this.customer) {
          this.loadOrders()
        }
      })
    },
    loadOrders() {
      const params = { user: this.customer['@id'] }
      if (this.date && this.date.split(' - ').length === 2) {
        const dates = this.date.split(' - ')
        // eslint-disable-next-line prefer-destructuring
        params['createdAt[after]'] = dates[0]
        // eslint-disable-next-line prefer-destructuring
        params['createdAt[before]'] = dates[1]
      }
      this.$Orders.getCollection({ params }).then(orderResponse => {
        this.orders = orderResponse.data['hydra:member']
        this.orders.forEach(order => {
          order.courierObj = {
            label: order.courier
              ? `${order.courier.givenName} ${order.courier.familyName}`
              : 'Courier not selected',
            id: order.courier ? order.courier['@id'] : null,
            numberId: order.courier ? order.courier.id : null,
          }
        })
        this.windowResize()
      })
    },
    goToEdit(id) {
      this.$router.push(`/customers/${id}/edit`)
    },
    windowResize() {
      if (document.querySelector('#vgt-table')) {
        const w = document.querySelector('#vgt-table').offsetWidth
        const collapsibleRows = document.querySelectorAll(
          '.collapsible-content-row',
        )
        collapsibleRows.forEach(row => {
          const bw = row.closest('.buttons').clientWidth
          row.style.width = `${w}px`
          row.style.marginLeft = `-${w - bw}px`

          let h = 0
          const cols = row.closest('tr').querySelectorAll('td')
          cols.forEach(col => {
            const span = col.querySelector(':scope > span:not(.buttons)')
            if (span && h < span.clientHeight) {
              h = span.clientHeight
            }
          })
          row.style.marginTop = `${h - 30}px`
        })
      }
    },
    updateCourier(order) {
      const body = {
        courier: order.courierObj.id,
      }
      this.$Orders
        .updateResource({ id: order.id, body })
        .then(response => {
          if (response.status === 200) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Courier updated',
              '',
              'success',
            )
          }
        })
        .catch(error => {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'danger',
            )
          }
        })
    },
  },
}
</script>
